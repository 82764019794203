footer {
    margin-top: 200px;
    width: 100%;
    height: 200px;
    background-color: rgb(87, 68, 86);;
    box-shadow: 0px -10px 5px rgba(128, 101, 126, 0.352);
}

.Credits{
    height: fit-content;
    margin: 30px;
    width: 50%;
    color: rgb(113, 90, 112);
    padding: 30px;
}

.linkStyle{
    color: rgb(149, 121, 147);
    margin-top: 30px;

    text-decoration: none;
}

.linkStyle:visited{
    color: rgb(149, 121, 147);
    margin-top: 30px;
    
    text-decoration: none;
}

.linkStyle:hover{
    color: rgb(124, 100, 123);
    margin-top: 30px;

    text-decoration: none;
}

.linkStyle:active{
    color: rgb(149, 121, 147);
    margin-top: 30px;

    text-decoration: none;
}
