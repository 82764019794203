.mainCont{
    width: 100%;
    min-height: 80vh;
}

.transImg{
    z-index: -100;
    opacity: 0.5;
    position: fixed;
    right: -300px;
    bottom: 0;
    filter: brightness(0) saturate(100%) invert(32%) sepia(11%) saturate(794%) hue-rotate(265deg) brightness(94%) contrast(87%);
}

header{
    color: #44333f;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

header h1{
    font-size: 50px;
    text-align: center;
}

header p{
    width: 100%;
    max-width: 1000px;
    font-size: 30px;
    text-align: center;
}

.czemuJa{
    color: #44333f;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.czemuJa h2{
    margin-top: 200px;
    font-size: 35px;
    width: 100%;
    
}

.czemuJa li{
    font-size: 25px;
    margin-top: 35px;
    width: fit-content;
}

.uslugi{
    margin-top: 100px;
    color: #44333f;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.uslugi h3{
    margin-top: 200px;
    font-size: 35px;
    width: 100%;
    
}

.uslugi li{
    font-size: 25px;
    margin-top: 35px;
    margin-left: 1vw;
}

.kontakt{
    margin-top: 100px;
    color: #44333f;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.kontakt h4{
    margin-top: 200px;
    font-size: 35px;
    width: 100%;
}

.kontakt li{
    font-size: 25px;
    margin-top: 35px;
    margin-left: 1vw;
}

.emailStyle{
    transition: color 0.1s;
    color: rgb(108, 40, 123);
    margin-top: 30px;

    text-decoration: none;
}

.emailStyle:visited{
    color: rgb(89, 30, 102);
    margin-top: 30px;
    
    text-decoration: none;
}

.emailStyle:active{
    color: rgb(108, 40, 123);
    margin-top: 30px;

    text-decoration: none;
}

.emailStyle:hover{
    transition: color 0.1s;
    color: rgb(41, 12, 48);
    margin-top: 30px;

    text-decoration: none;
}

@media only screen and (max-width: 1000px) {

    header{
        text-align: justify;
        text-justify: inter-word;
        width: 85%;
        max-width: 800px;
        margin: auto;
        margin-top: 100px;
    }
    
    header h1{
        font-size: 30px;
    }
    
    header p{
        font-size: 20px;
    }

    .czemuJa{
        max-width: 800px;
        margin: auto;
    }

    .czemuJa h2{
        margin-top: 75px;
        text-align: center;
        font-size: 28px;
    }

    .czemuJa ul{
        padding-right: 20px;
    }
    
    .czemuJa li{
        margin-top: 18px;
        font-size: 18px;
    }

    .uslugi{
        max-width: 800px;
        margin: auto;
    }

    .uslugi h3{
        margin-top: 75px;
        text-align: center;
        font-size: 28px;
    }

    .uslugi ul{
        padding-right: 20px;
    }
    
    .uslugi li{
        margin-top: 18px;
        font-size: 18px;
    }

    .kontakt{
        max-width: 800px;
        margin: auto;
    }
    
    .kontakt h4{
        margin-top: 75px;
        text-align: center;
        font-size: 28px;
    }

    .kontakt ul{
        padding-right: 20px;
    }
    
    .kontakt li{
        margin-top: 18px;
        font-size: 18px;
    }
  }