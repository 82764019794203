@import url('https://fonts.googleapis.com/css2?family=Playwrite+CO:wght@100..400&display=swap');

nav{
    font-family: "Playwrite CO", cursive;
    width: 100%;
    color: #44333f;
    display: flex;
    flex-direction: column;
}

nav h1{
    text-align: center;
    width: fit-content;
    font-size: 30px;
    height: 25px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

nav h2{
    text-align: center;
    width: fit-content;
    font-size: 20px;
    height: 15px;
    align-items: flex-start;
    display: flex;
    padding-left: 20px;
    margin-top: 0px;
}

@media only screen and (max-width: 1000px) {
    nav h1{
        align-items: flex-start;
        display: flex;
        font-size: 20px;
    }
    
    nav h2{
        font-size: 13px;
    }
}